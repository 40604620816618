import { useState } from 'react';
import './AmountCalculator.scss';

interface AmountCalculatorProps {
    title: string;
    reset: string;
    data: any;
}

const AmountCalculator: React.FC<AmountCalculatorProps> = ({ title, reset, data }) => {
    const [values, setValues] = useState(data);
    const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
        const value = parseFloat(e.target.value);
        const difference = (value * 100) / data[index].default;
        const v = [...data];
        v.forEach((item: any) => {
            if (item.default === index) return;
            item.value = parseFloat((difference * item.default / 100).toFixed(2));
        });
        setValues(v);
    }
    const handleMultiplierClick = (multiplier: number) => () => {
        const v = [...data];
        v.forEach((item: any) => {
            item.value = parseFloat(((item.value || item.default) * multiplier).toFixed(2));
        });
        setValues(v);
    }
    const handleResetlick = () => () => {
        const v = [...data];
        v.forEach((item: any) => {
            item.value = item.default;
        });
        setValues(v);
    }
    return (
        <div className="amount-calculator">
            <h2>{title}</h2>
            <div className="items">
                {
                    values.map((item: any, index: number) => (
                        <div className="item" key={index}>
                            <input type="number" min="0" defaultValue={item.default} value={item.value} onChange={(e) => handleValueChange(e, index)} />
                            <small><span className="type">{item.type}</span></small>
                            <div className="item-label">{item.label}</div>
                        </div>
                    ))
                }
            </div>
            <div className="fast-buttons">
                <button onClick={handleResetlick()}>{reset}</button>
                <button onClick={handleMultiplierClick(2)}>x2</button>
                <button onClick={handleMultiplierClick(3)}>x3</button>
                <button onClick={handleMultiplierClick(4)}>x4</button>
            </div>
        </div>
    );
};

export default AmountCalculator;