import Equipment from "../fragments/Equipment";
import Warning from "../fragments/Warning";
import Info from "../fragments/Info";
import Steps from "../fragments/Steps";
import StepsFooter from "../fragments/StepsFooter";

import "./Guide.scss";
import AmountCalculator from "../fragments/AmountCalculator";

interface GuideProps {
    onBack: any;
    guide: any;
}

const Guide: React.FC<GuideProps> = ({ onBack, guide }) => {
    return (
        <div className="guide">
            <h1>{guide.title}</h1>
            <h3>{guide.subtitle}</h3>
            {
                guide.info &&
                <Info text={guide.info.text}>
                    {
                        guide.info.warnings && guide.info.warnings.map((warning: string, index: number) => (
                            <Warning key={index} text={warning}></Warning>
                        ))
                    }
                </Info>
            }
            {
                guide.equipment &&
                <Equipment title={guide.equipment.title} data={guide.equipment.items} />
            }
            {
                guide.calculator &&
                <AmountCalculator title={guide.calculator.title} reset={guide.calculator.reset} data={guide.calculator.items} />
            }
            {
                guide.steps &&
                <Steps title={guide.steps.title} steps={guide.steps.items} info={guide.steps.info} />
            }
            <StepsFooter onBack={onBack} />
        </div>
    );
}

export default Guide;