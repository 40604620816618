import React from 'react';
import './Info.scss';

interface Info {
  text: string;
  children?: React.ReactNode;
}

const InfoComponent: React.FC<Info> = ({
  text,
  children
}) => {
  return (
    <div className='info'>
        <div>{text}</div>
        {children ? <div>{children}</div> : null}
    </div>
  );
};

export default InfoComponent;