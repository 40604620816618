import IconGrid from "./IconGrid";
import './Equipment.scss';

interface EquipmentProps {
    title: string;
    data: any;
}

const Equipment: React.FC<EquipmentProps> = ({ title, data }) => {
    return (
        <div className="equipment">
            <h2>{title}</h2>
            <IconGrid items={data} />
        </div>
    );
};

export default Equipment;