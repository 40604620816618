import React from "react";
import "./IconGrid.scss";

interface IconGridProps {
  items: { icon: string; label: string, amount?: string }[];
}

const IconGrid: React.FC<IconGridProps> = ({ items }) => {
  return (
    <div className="icon-grid-container">
      <div className="grid">
        {items.map((item, index) => (
          <div className="grid-item" key={index}>
            <img src={`${process.env.PUBLIC_URL}/${item.icon}`} alt={item.label} className="icon" />
            <p className="label">{item.label}</p>
            <small className="amount">{item.amount || '-'}</small>
          </div>
        ))}
      </div>
    </div>
  );
};

export default IconGrid;