import React from 'react';
import './StepsFooter.scss';

interface StepsFooterProps {
    onBack: () => void
};

const StepsFooter: React.FC<StepsFooterProps> = ({ onBack }) => {
  return (
    <div className='steps-footer'>
        <button onClick={onBack}>BACK</button>
    </div>
  );
};

export default StepsFooter;