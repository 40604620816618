import React, { createContext, useEffect, useState } from 'react';
import { ReactNode } from 'react';

interface LocalizationContextType {
    translations: { [key: string]: any };
    changeLanguage: React.Dispatch<string>;
  }

const LocalizationContext = createContext<LocalizationContextType | undefined>(undefined);

export const LocalizationProvider = ({ children }: { children: ReactNode }) => {
    const [locale, setLocale] = useState('en');
    const [translations, setTranslations] = useState<{ [key: string]: any}>({});

    useEffect(() => {
        const loadTranslations = async () => {
            try {
                const response = await fetch(`${process.env.PUBLIC_URL}/locales/${locale}.json`);
                const data = await response.json();
                setTranslations(data);
            } catch (error) {
                console.error("Failed to load locale", error);
            }
        };

        loadTranslations();
    }, [locale]);

    const changeLanguage = (newLocale: string) => {
        setLocale(newLocale);
    };

    return (
        <LocalizationContext.Provider value={{ translations, changeLanguage }}>
            {children}
        </LocalizationContext.Provider>
    );
}

export const useLocalization = () => {
    return React.useContext(LocalizationContext);
}