import React, { useState } from "react";
import { Step } from "./Steps.types";

import "./Steps.scss";

interface StepsProps {
    title: string;
    steps: Step[];
    info?: string;
}

const Steps: React.FC<StepsProps> = ({ title, steps, info }) => {
    const [state, setState] = useState<'initial' | 'open'>('open'); // Add state hook here
    const [expandedStep, setExpandedStep] = useState<number | null>(null); // Tracks which step is expanded
    const [showCheckboxes, setShowCheckboxes] = useState(false);           // Tracks if checkboxes are visible

    const handleToggleStep = (stepNumber: number) => {
        setExpandedStep((prev) => (prev === stepNumber ? null : stepNumber)); // Toggle step
    };

    const handleStartStep = () => {
        setShowCheckboxes(true); // Show checkboxes when the button is clicked
    };

    const handleCompleteStep = () => {
        setShowCheckboxes(false); // Hide checkboxes when the button is clicked
    };

    const handleOpenClick = () => {
        setState('open'); // Set state to 'open' when the button is clicked
    }

    return (
        <div className="steps-container">
            <>
                {state === 'initial' &&
                    <button onClick={handleOpenClick}>OPEN</button>
                }
                {
                    state === 'open' &&
                    <>
                        <h2>{title}</h2>
                        {info && <div className="info">{info}</div>}
                        <div className="steps">
                            {steps.map((step: any) => (
                                <div className="step-card" key={step.number}>
                                    <div className="step-header">
                                        <div className="step-number">
                                            {showCheckboxes ? (
                                                <input type="checkbox" />
                                            ) : (
                                                step.number
                                            )}
                                        </div>
                                        <div className="step-title">
                                            <span className="text">{step.title}</span>
                                            {
                                                step.description && (
                                                    <div className="toggle-icon" onClick={() => handleToggleStep(step.number)}>
                                                        <span>{expandedStep === step.number ? "▼" : "►"}</span>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </div>
                                    {expandedStep === step.number && (
                                        <div className="step-content">
                                            {
                                                Array.isArray(step.description) ? (
                                                    step.description.map((desc: string | { title: string, link?: string }, index: number) => {
                                                        if (typeof desc === 'object') {
                                                            if (desc.link) {
                                                                return <p key={index} className="link" onClick={() => {
                                                                    window.location.replace((desc as any).link);
                                                                }}>- {desc.title}</p>
                                                            }
                                                        }
                                                        return <p key={index}>- {desc as string}</p>
                                                    })
                                                ) : (
                                                    <p>{step.description}</p>
                                                )
                                            }
                                            <div className="icons">
                                                {step.iconLeft && (
                                                    <img src={step.iconLeft} alt="Left Icon" />
                                                )}
                                                {step.iconRight && (
                                                    <img src={step.iconRight} alt="Right Icon" />
                                                )}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            ))}
                        </div>
                        <div className="navigation">
                            {!showCheckboxes && <button onClick={handleStartStep}>START</button>}
                            {showCheckboxes && <button onClick={handleCompleteStep}>COMPLETE</button>}
                        </div>
                    </>
                }
            </>

        </div>
    );
};

export default Steps;