import React from 'react';
import './Warning.scss';

interface Warning {
  text: string;
}

const WarningComponent: React.FC<Warning> = ({
  text
}) => {
  return (
    <div className='warning'>{text}</div>
  );
};

export default WarningComponent;