import { useEffect, useState } from "react";
import { useLocalization } from "./LocalizationContext";

import LandingScreen from "./components/screens/LandingScreen";
import Guide from "./components/screens/Guide";

const App = () => {
  const localization = useLocalization();
  const translations = localization ? localization.translations : {};
  const [currentPage, setCurrentPage] = useState<string>(window.location.hash || '#home'); // Track the current page

  const guidesData = [
    {
      id: "agar_plate_spores",
      title: translations.agar_plate_spores?.title,
      subtitle: translations.agar_plate_spores?.subtitle,
      icon: "/assets/icon-petri-dish-agar-mycelium.png",
    }
  ];

  useEffect(() => {
    const handleHashChange = () => {
      setCurrentPage(window.location.hash || "#home");
    };

    // Listen for hash changes
    window.addEventListener("hashchange", handleHashChange);

    // Clean up event listener
    return () => {
      window.removeEventListener("hashchange", handleHashChange);
    };
  }, []);

  const renderPage = () => {
    if (currentPage === "#home") {
      return <LandingScreen onGuideSelect={(guide: any): void => {
        setCurrentPage(`#${guide}`)
      }} />;
    }

    const guideId = currentPage.replace("#", "");
    const guide = translations.guides?.find((g: any) => g.id === guideId);
    return <Guide onBack={() => setCurrentPage("#home")} guide={guide} />;
  };

  return (
    <div className="app">
      {renderPage()}
    </div>
  );
};

export default App;
