import React from "react";
import { useLocalization } from "../../LocalizationContext";
import "./LandingScreen.scss";

interface Guide {
    id: string;
    title: string;
    icon: string;
    subtitle?: string;
}

interface LandingScreenProps {
    onGuideSelect: (guide: string) => void;
}

const LandingScreen: React.FC<LandingScreenProps> = ({ onGuideSelect }) => {
    const localization = useLocalization();
    const translations = localization ? localization.translations : {};
    const guides = translations.guides || [];

    return (
        <div className="landing-screen">
            <h1>{ translations.landing?.title }</h1>
            <h3>{ translations.landing?.subtitle }</h3>
            <h5>v1.0.0</h5>
            <div className="blocks-container">
                {guides.map((guide: Guide) => (
                    <div
                        className="block"
                        key={guide.id}
                        onClick={() => onGuideSelect(guide.id)}>
                        <img className="icon" src={`${process.env.PUBLIC_URL}/${guide.icon}`} alt={guide.title} />
                        <div className="block-content">
                            <h2>{guide.title}</h2>
                            { guide.subtitle && <h3>{guide.subtitle}</h3> }
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default LandingScreen;
